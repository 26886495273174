<ng-container class="carousel" *ngIf="homeInfo.topBanners && homeInfo.topBanners.length>0">
  <app-owl-carousel [banners]="homeInfo.topBanners"></app-owl-carousel>
  <!--訂單檢視/我的票夾-->
  <div class="main-function">
    <div>
      <a href="javascript:void(0)" (click)="goOrder()" style="color: #494949;">
        <img src="assets/images/icashpay-icon/w-order.svg">檢視訂單
      </a>
    </div>
    <div>
      <a href="javascript:void(0)" (click)="goActivity()" style="color: #494949;">
        <img src="assets/images/icashpay-icon/w-news.svg">活動快訊
      </a>
    </div>
    <div>
      <a href="javascript:void(0)" (click)="goTicket()" style="color: #494949;">
        <img src="assets/images/icashpay-icon/w-wallet.svg">我的票夾
      </a>
    </div>
  </div>
</ng-container>

<div class="container teamCarousel">
  <ng-container *ngIf="brands && brands.length > 0">
    <app-teamCarousel [banners]="brands"></app-teamCarousel>
  </ng-container>
</div>
<div class="home container black-line">
  <section class="accumulated-points" [hidden]="homeInfo.activities && homeInfo?.activities?.length==0">
    <div class="accumulated-points__row-wrap">
      <div class="accumulated-points__slide-button-wrap">
        <div class="accumulated-points__slide-button accumulated-points__slide-button--prev"
          [ngClass]="{'accumulated-points__slide-button--hide': brandSliderCounter==0}"
          (click)="onBrandSliderChange('prev',$event)">
          <a href="#/">
            <span><img src="/assets/images/icon/arrow_top_pink.svg" alt=""></span>
          </a>
        </div>
        <div class="accumulated-points__slide-button accumulated-points__slide-button--next"
          [ngClass]="{'accumulated-points__slide-button--hide': brandSliderCounter==1}"
          (click)="onBrandSliderChange('next',$event)">
          <a href="#/">
            <span><img src="/assets/images/icon/arrow_top_pink.svg" alt=""></span>
          </a>
        </div>
      </div>
      <div class="accumulated-points__row">
        <div class="accumulated-points__col" *ngFor="let item of homeInfo.activities">
          <div class="accumulated-points__item">
            <ng-container *ngIf="item.cContentType!=0">
              <a class="accumulated-points__image-wrap" href="{{item.cUrl}}" target="_blank">
                <img class="accumulated-points__image" src="{{item.cImg}}" alt="">
              </a>
              <a class="accumulated-points__button" href="{{item.cUrl}}" target="_blank">了解更多</a>
            </ng-container>
            <!--  -->
            <ng-container *ngIf="item.cContentType==0">
              <a class="accumulated-points__image-wrap" href="{{'/event/' + item.cType + '/' + item.cID}}"
                routerLink="{{'/event/' + item.cType + '/' + item.cID}}">
                <img class="accumulated-points__image" src="{{item.cImg}}" alt="">
              </a>
              <a class="accumulated-points__button" href="{{'/event/' + item.cType + '/' + item.cID}}"
                routerLink="{{'/event/' + item.cType + '/' + item.cID}}">了解更多</a>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- 本期主打 -->
  <section class="best-seller recommended-seller" [hidden]="homeInfo.hitProducts && homeInfo.hitProducts.length==0">
    <div class="best-seller__title-wrap" *ngIf="1&& mobileBrandList.productLists.length">
      <!-- <h2 class="best-seller__title">本期主打</h2> -->
      <h2 class="best-seller__title">{{homeInfo.hotTitle}}</h2>
    </div>

    <div class="recommended-brand__brand-slider-mobile-text" *ngIf="1&& mobileBrandList.productLists.length">
      <div class="brand-slider-mobile__row-wrap">
        <swiper [spaceBetween]="12" [loop]="false" [slidesPerView]="2.12" [navigation]="false" class="mySwiper butnswiper">
          <ng-template swiperSlide *ngFor="let list of mobileBrandList.productLists;">
            <div class="brand-slider-mobile__col" *ngFor="let item of list.products">
              <ng-container *ngIf="item.label">
                <div class="best-seller__labeltag"
                  [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
                  {{item.label.text}}
                </div>
              </ng-container>
              <div class="brand-slider-mobile__item">
                <a routerLink="/product/detail/{{item.id}}">
                  <div class="image-container">
                    <img src="{{item.img}}" alt="">
                  </div>
                  <div style="margin-bottom: 10px;">
                    <div class="brand-slider-mobile__name">{{item.name}}</div>
                  </div>
                  <div class="best-seller__price__main">
                    <p class="best-seller__price best-seller__price--point" *ngIf="item.points">
                      {{item.points | number}}點
                    </p>
                    <p class="best-seller__price best-seller__price--point" *ngIf="item.pP_Price && !item.points">
                      {{item.pP_Price | number}}元
                    </p>

                    <span class="oldprice" *ngIf="item.points! < item.discountPrice! && item.discountPrice !== 0 && item.points">{{item.discountPrice}}點</span>
                    <span class="oldprice" *ngIf="item.points! < item.discountPrice! && item.discountPrice !== 0 && item.pP_Price && !item.points">{{item.discountPrice}}元</span>
                  </div>
                </a>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>

      <div class="best-seller__more-butn">
        <a class="best-seller__more-link" routerLink="/product/recommend/0">查看更多</a>
      </div>

    </div>

  </section>
  <!-- 推薦商品 -->
  <section class="best-seller" [hidden]="homeInfo?.hotProducts2?.length==0">
    <div class="best-seller__title-wrap">
      <h2 class="best-seller__title">{{homeInfo.hotTitle2}}</h2>
    </div>
    <div class="best-seller__row">
      <!-- each item -->
      <div class="best-seller__col" *ngFor="let item of homeInfo.hotProducts2">
        <ng-container *ngIf="item.label">
          <div class="best-seller__labeltag"
            [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
            {{item.label.text}}
          </div>
        </ng-container>
        <div class="best-seller__item">
          <div class="best-seller__image-wrap">
            <div routerLink="/product/detail/{{item.id}}" class="out-of-stock-mask" *ngIf="item.isSoldOut">
              <div class="out-of-stock-mask__circle">
                <span class="out-of-stock-mask__text">已售完</span>
              </div>
            </div>

            <a routerLink="/product/detail/{{item.id}}" class="best-seller__product-image">
              <img class="" src="{{item.img}}" alt="">
            </a>
          </div>
          <div class="best-seller__name-wrap">
            <a routerLink="/product/detail/{{item.id}}" class="best-seller__name">
              {{item.name}}
            </a>
          </div>
          <div class="best-seller__price-wrap">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <p class="best-seller__price best-seller__price--point" *ngIf="item.points">
                  {{item.points | number}}點
                </p>
                <span class="oldprice" *ngIf="item.points! < item.discountPrice! && item.discountPrice !== 0 && item.points">{{item.discountPrice}}點</span>
                <p class="best-seller__price best-seller__price--point" *ngIf="item.pP_Price && !item.points">
                  {{item.pP_Price | number}}元
                </p>
                <span class="oldprice" *ngIf="item.points! < item.discountPrice! && item.discountPrice !== 0 && item.pP_Price && !item.points">{{item.discountPrice}}元</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="best-seller__more-butn"><a class="best-seller__more-link" routerLink="/product/recommend/1">查看更多</a>
    </div>
  </section>
  <!--toDo  這塊先塞熱銷商品 之後確認邏輯再調整 -->
  <section class="best-seller" [hidden]="homeInfo?.hotSellProducts?.length==0">
    <div class="best-seller__title-wrap">
      <!-- 資料 hotSellProducts -->
      <h2 class="best-seller__title">推薦首選</h2>
      <!-- <a class="bring-Information" routerLink="/product/recommend">查看更多</a> -->
    </div>
    <div class="best-seller__row">
      <!-- each item -->
      <div class="best-seller__col" *ngFor="let item of homeInfo.hotSellProducts">
        <ng-container *ngIf="item.label">
          <div class="best-seller__labeltag"
            [ngStyle]="{color:item.label.color,backgroundColor: item.label.backGroundColor}">
            {{item.label.text}}
          </div>
        </ng-container>
        <div class="best-seller__item">
          <div class="best-seller__image-wrap">
            <div routerLink="/product/detail/{{item.id}}" class="out-of-stock-mask" *ngIf="item.isSoldOut">
              <div class="out-of-stock-mask__circle">
                <span class="out-of-stock-mask__text">已售完</span>
              </div>
            </div>
            <a routerLink="/product/detail/{{item.id}}" class="best-seller__product-image">
              <img class="" src="{{item.img}}" alt="">
            </a>
          </div>
          <div class="best-seller__name-wrap">
            <a routerLink="/product/detail/{{item.id}}" class="best-seller__name">
              {{item.name}}
            </a>
          </div>
          <div class="best-seller__price-wrap">
            <div class="d-flex align-items-center">
              <div *ngIf="item.points" class="d-flex align-items-center">
                <p class="product-list__price product-list__price--point">
                  {{ item.points | number }}點
                </p>
                <span class="oldprice" *ngIf="item.points! < item.discountPrice! && item.discountPrice !== 0">{{item.discountPrice}}點</span>
              </div>
              <div *ngIf="item.pP_Price && !item.points" class="d-flex align-items-center">
                <p class="product-list__price product-list__price--point">
                  {{ item.pP_Price | number }}元
                </p>
                <span class="oldprice" *ngIf="item.points! < item.discountPrice! && item.discountPrice !== 0">{{item.discountPrice}}元</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="best-seller__more-butn"> <a class="best-seller__more-link" routerLink="/product/recommend/2">查看更多</a>

  <!-- <button class="best-seller__more-butn" (click)="pay()">pay
</button> -->
      </div>
    </div>
  </section>
</div>
<section class="recommended-brand" [hidden]="homeInfo?.brands?.length==0">
  <div class="recommended-brand__title-wrap">
    <h2 class="recommended-brand__title">推薦品牌</h2>
  </div>

  <!-- if width >= 768 -->
  <div class="recommended-brand__brand-slider-desktop" *ngIf="!isMobile">
    <div class="brand-slider-desktop__row-wrap">
      <div class="brand-slider-desktop__row">
        <div class="brand-slider-desktop__col" *ngFor="let item of homeInfo.brands">
          <div class="brand-slider-desktop__item">
            <a routerLink="/product/list/{{item.cat1_id}}/{{item.cat2_id}}">
              <img src="{{item.logo}}" alt="">
            </a>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <ol class="brand-slider-desktop__indicators">
      <li class="indicators__item" *ngFor="let item of createEmptyArray(brandCarouselTotalPages);let i=index;">
        <a href="" class="indicators__link" [ngClass]="{'indicators__link--active': brandCarouselCounter==i}"
          (click)="onBrandCarouselChange(i,$event)"></a>
      </li>
    </ol>
  </div>

  <div class="recommended-brand__brand-slider-mobile" *ngIf="isMobile && 1">
    <div class="brand-slider-mobile__row-wrap">
      <swiper [spaceBetween]="10"  [slidesPerView]="4.3" [navigation]="false" class="mySwiper butnswiper" [autoplay]="{delay: 5000,disableOnInteraction: false}"
        [navigation]="false" class="mySwiper">
        <ng-template swiperSlide *ngFor="let list of mobileBrandList.brandLists;">
          <div class="brand-slider-mobile__col" *ngFor="let item of list.brands">
            <div class="brand-slider-mobile__item">
              <a routerLink="/product/list/{{item.cat1_id}}/{{item.cat2_id}}">
                <img src="{{item.logo}}" alt="">
                <div class="brand-slider-mobile__name">{{item.name2|slice:0:10}}</div>
              </a>
            </div>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>


  <div class="best-seller__more-butn"> <a class="best-seller__more-link" routerLink="/product/overview">查看更多</a>
  </div>
</section>

<!-- modal -->
<app-modal [isModalOpen]="isModalOpen" (modalClose)="isModalOpen=$event;">
  <h4 headerContent class="general-modal__title">{{modalTiTle}}</h4>
  <div bodyContent class="general-modal__text-wrap">
    <p class="general-modal__text">{{modalMsg}}</p>
  </div>
  <div footerContent class="general-modal__button-wrap">
    <button class="general-modal__button general-modal__button--confirm general-modal__button--only"
      (click)="isModalOpen=false;">確認</button>
  </div>
</app-modal>
